import React from "react"
import Masthead from '../components/Masthead'
import BlockSimple from '../components/BlockSimple'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlockLinks from "../components/BlockLinks"
import { Link } from "gatsby"
import "../components/ImagePuzzle/styles.css"
import FeatureCards from "../components/FeatureCards"

const features = [{
    title: "Nature",
    image: "/mono.jpeg",
    description: "The wildlife and vegetation in Costa Rica is a must see! We can set up tours for you and your family to witness this raw beauty."
  },
  {
    title: "Flow",
    image: "/flow.jpeg",
    description: "If clean, crystal clear water is what your craving for, we've got you covered. We provide tours throughout rivers, lakes and ocean. You'll disover the wild wolrd underwater."
  },
  {
    title: "Pura Vida Lifestyle",
    image: "/girl.jpeg",
    description: "Ticos are welcoming, warm and helpful. We can arrange tours or adventures for you to truly get to know what Pura Vida can be."
  }
]

const IndexPage = () => (
  <Layout>
    <SEO title="Costa Rica Best Choice" />
    <Masthead/>
    <BlockSimple title="Safe, fast, and reliable shuttle and tours servicing the Liberia airport, Tamarindo, Santa Cruz, Nicoya, Monteverde, Playa Grande, Nosara and all of Guanacaste and Central Costa Rica."/>
    <div className="imagePuzzle__wrapper">
      <div className="imagePuzzle">
        <Link to="/shuttle-services" className="imagePuzzle__1 imagePuzzle__img"><span className="imagePuzzle__text">Private shuttle service</span></Link>
        <Link to="/tours" className="imagePuzzle__2 imagePuzzle__img"><span className="imagePuzzle__text">Mid Day or full day tours</span></Link>
        <Link to="/destinations" className="imagePuzzle__3 imagePuzzle__img"><span className="imagePuzzle__text">Custom Experiences</span></Link>
      </div>
    </div>
    <BlockLinks/>

    <div className="feature-card__wrapper">
      <div className="feature-card">
        <h2 className="feature-card__heading">Elements to vacation Costa Rica style</h2>
          <FeatureCards features={features}/>
      </div>
    </div>

  </Layout>
)

export default IndexPage
