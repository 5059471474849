import React from 'react'
import { Link } from "gatsby"
import './styles.css'


const BlockLinks = ({ siteTitle }) => (
  <div className="block-links__wrapper">
    <div className="block-links">
      <h2 className="block-links__heading">Plan your trip</h2>
      <div className="block-links__links">
        <Link className="block-links__link" to="/">
          <img className="block-links__image" src="https://res.cloudinary.com/dqzg3xd5c/image/upload/c_fill,g_south,h_177,w_265/v1556062353/1fdcfde4-1ab2-4b4a-b960-f565f564c82b.jpg"/>
          <h3 className="block-links__title">Food & Drink</h3>
        </Link>
        <Link className="block-links__link" to="/">
          <img className="block-links__image" src="https://res.cloudinary.com/dqzg3xd5c/image/upload/c_fill,h_177,w_265/v1556062084/4280ed12-c0af-409d-8117-4c5aaacd22db.jpg"/>
          <h3 className="block-links__title">Transportation</h3>
        </Link>
        <Link className="block-links__link" to="/">
          <img className="block-links__image" src="https://res.cloudinary.com/dqzg3xd5c/image/upload/c_fill,h_177,w_265/v1556062251/1280px-Guanacaste_Province__Punta_Islita__Costa_Rica_-_panoramio__3.jpg"/>
          <h3 className="block-links__title">Lodging</h3>
        </Link>
        <Link className="block-links__link" to="/">
          <img className="block-links__image" src="https://res.cloudinary.com/dqzg3xd5c/image/upload/c_fill,g_south,h_177,w_265/v1556062353/faa948f0-4a7c-4e77-8cd8-73f208a0ff20.jpg"/>
          <h3 className="block-links__title">Guides & Maps</h3>
        </Link>
      </div>
    </div>
  </div>
)

export default BlockLinks
